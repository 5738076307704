import React from 'react'

// App components
import Button from '../../components/Button'
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'

// App utils
import { rhythm } from '../../utils/typography'
import { themeStyles, metrics, colors, presets } from '../../utils/theme'

const styles = {
  half: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '300px',
    minWidth: '300px',
    alignSelf: 'stretch',
  },
}

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div css={themeStyles.contentWidth}>
          <PageTitle title="New Patient Forms" bannerKey={'THE_NEXT_STEP'} />
          <h2
            css={{
              textAlign: 'center',
              marginTop: metrics.defaultMargin,
              ...themeStyles.textPadding,
              fontWeight: 100,
              color: colors.brightBlue,
            }}
          >
            Please complete the <strong>new patient form</strong> before your
            first visit.
          </h2>
          <p css={{ textAlign: 'center' }}>We have two EASY options:</p>
          <div
            css={{
              [presets.mobile]: { flexDirection: 'row' },
              display: 'flex',
              flexDirection: 'column',
              marginTop: rhythm(2),
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            <div css={[styles.half, { backgroundColor: colors.lightGrey }]}>
              <h2 css={{ fontWeight: 100, color: 'black' }}>
                Fill out the form{' '}
                <strong css={{ color: colors.brightBlue }}>online</strong>
              </h2>
              <p>(Easiest and Fastest)</p>
              <Button
                text="Online Form"
                href="https://intakeq.com/new/6boq04"
              />
            </div>
            <div
              css={{
                [presets.mobile]: {
                  minHeight: '300px',
                  flexDirection: 'row',
                  minWidth: 0,
                  width: '0',
                },
                width: '100%',
                flexDirection: 'column',
                minHeight: 0,
                backgroundColor: colors.lightGrey,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                css={{
                  [presets.mobile]: {
                    height: '260px',
                    borderLeft: `2px solid ${colors.mediumGrey}`,
                    width: 0,
                    borderTop: 0,
                  },
                  height: 0,
                  borderLeft: 0,
                  borderTop: `2px solid ${colors.mediumGrey}`,
                  width: '80%',
                }}
              />
            </div>
            <div css={[styles.half, { backgroundColor: colors.lightGrey }]}>
              <h2 css={{ fontWeight: 100, color: 'black' }}>
                <strong css={{ color: colors.brightBlue }}>Print</strong> the
                form
              </h2>
              <p>(Pen and Paper Still Works!)</p>
              <Button
                text="Download Printable Form"
                href="https://res.cloudinary.com/collins-ortho/image/upload/v1688341686/pdfs/Collins_Orthodontics_HH_form.pdf"
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Page
